<template>
  <v-container fluid>
    <v-row class="justify-center text-center">
      <v-col cols="12" sm="12" md="12" lg="10">
        <h2 class="font-weight-bold mt-4 mb-10">Lista de formularios de hoy</h2>
        <v-row class="mb-2">
          <v-col cols="12" class="mb-3 mt-n5">
            <v-btn color="success" @click="mostrar_filtros = !mostrar_filtros">
              <v-icon class="mr-2" v-if="!mostrar_filtros">
                mdi-filter-menu
              </v-icon>
              <v-icon class="mr-2" v-else> mdi-filter-menu-outline </v-icon>
              Filtros
            </v-btn>
          </v-col>

          <v-col
            v-if="mostrar_filtros"
            cols="12"
            sm="3"
            md="2"
            lg="2"
            class="mb-n8 mt-n6 mt-sm-0 mt-lg-0"
          >
            <label>N° Placa</label>

            <v-text-field
              :rules="[
                (v) =>
                  /^[A-Za-z0-9]*$/.test(v) || 'Solo deben ser numeros y letras',
              ]"
              @keyup.enter="buscarFormularios"
              v-model="placa"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col
            v-if="mostrar_filtros"
            cols="12"
            sm="3"
            md="2"
            lg="2"
            class="mb-n10"
          >
            <label>Orden Trabajo</label>
            <v-text-field
              type="number"
              @keyup.enter="buscarFormularios"
              v-model="orden_trabajo"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col
            v-if="mostrar_filtros"
            cols="12"
            sm="3"
            md="2"
            lg="2"
            class="mb-n10"
          >
            <label>Runt</label>
            <v-text-field
              type="number"
              @keyup.enter="buscarFormularios"
              v-model="runt"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col
            v-if="mostrar_filtros"
            cols="12"
            sm="3"
            md="3"
            lg="2"
            class="mb-n8"
          >
            <label for="">Fecha inicial</label>
            <v-menu
              v-model="menu_fecha_inicial"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha_inicial"
                  @click:append="menu_fecha_inicial = true"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha_inicial"
                @input="menu_fecha_inicial = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" v-if="mostrar_filtros" sm="3" md="3" lg="2">
            <label for="">Fecha final</label>
            <v-menu
              v-model="menu_fecha_final"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fecha_final"
                  dense
                  outlined
                  append-icon="mdi-calendar"
                  @click:append="menu_fecha_final = true"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Campo requerido']"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="fecha_final"
                :min="fecha_inicial"
                @input="menu_fecha_final = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            v-if="mostrar_filtros"
            cols="12"
            sm="2"
            md="2"
            lg="2"
            class="d-flex align-center justify-center mt-n8 mt-sm-0 mt-md-n5 mt-lg-0 mb-3 mb-sm-0 mb-lg-0"
          >
            <v-btn color="primary" @click="buscarFormularios">
              Buscar
              <v-icon class="ml-1"> mdi-magnify </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :items-per-page="-1"
          v-if="!buscando_formularios"
          :header-props="{ sortByText: 'Ordenar por' }"
          :headers="headers"
          :items="formularios"
          no-data-text="No se encontraron resultados"
          class="text-center"
          elevation="0"
        >
          <!-- Menú de acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-menu transition="slide-y-transition" bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="getItem(item)"
                  :disabled="item.fecha_revision == '--'"
                >
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-undo-variant</v-icon>
                    Reestablecer
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="redirectToRevision(item.id_formulario)">
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-text-box-search</v-icon>
                    Visualizar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:[`item.revisado`]="{ item }">
            <v-icon v-if="item.revisado == 1" color="success">mdi-check</v-icon>
            <v-icon v-else-if="item.revisado == 0" color="error"
              >mdi-close</v-icon
            >
          </template>

          <template v-slot:[`item.aprobado`]="{ item }">
            <v-icon v-if="item.aprobado == 1" color="success">mdi-check</v-icon>
            <v-icon v-else-if="item.aprobado == 2" color="error"
              >mdi-close</v-icon
            >
            <v-icon v-else color="grey lighten-1">mdi-minus</v-icon>
          </template>

          <template v-slot:item.tarifa="{ item }">
            <div v-if="item.tipo_revision != 0 && item.tipo_revision != 2">
              ${{ item.tarifa | moneda }}
            </div>
            <div v-else>No Aplica</div>
          </template>
        </v-data-table>
        <v-col
          cols="12"
          v-else
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
          "
        >
          <v-spacer></v-spacer>
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-spacer></v-spacer>
        </v-col>

        <!-- Menú mediante el cual permite reestablecer un formulario con una confirmación -->
        <v-dialog persistent v-model="dialogoConfirmacion" width="400px">
          <v-card class="mx-auto text-center justify-center" width="400px">
            <v-card-title class="text-center justify-center mb-4 grey lighten-4"
              >Reestablecer formulario</v-card-title
            >
            <v-card-text class="justify-center text-center">
              <div class="mb-2">
                <v-icon size="60">mdi-help-circle-outline</v-icon>
              </div>

              <span
                >¿Está seguro de que desea reestablecer este formulario?</span
              >
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                color="success"
                small
                @click="restoreItem()"
                :loading="loaderReset"
              >
                <v-icon small class="mr-1">mdi-check</v-icon>
                Sí
              </v-btn>
              <v-btn color="error" small @click="dialogoConfirmacion = false">
                <v-icon small class="mr-1">mdi-close</v-icon>
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiLista from "../lista_preguntas/services/ListaFormsDataService.js";

export default {
  data() {
    return {
      mostrar_filtros: false,
      formularios: [], //Listado de formularios

      placa: null,
      orden_trabajo: null,

      runt: null,

      fecha_inicial: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menu_fecha_inicial: false,

      fecha_final: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      menu_fecha_final: false,

      buscando_formularios: false,

      //Encabezados del data table que se muestra en la vista
      headers: [
        {
          text: "N° Orden Trabajo",
          sortable: false,
          filterable: false,
          value: "ot_id",
        },
        // {
        //   text: "Tarifa",
        //   value: "tarifa",
        //   align: "start",
        // },
        {
          text: "Runt",
          sortable: false,
          filterable: false,
          value: "runt",
        },
        {
          text: "Tipo revision",
          sortable: false,
          filterable: false,
          value: "tipo_revision_texto",
        },
        {
          text: "Técnico",
          align: "start",
          sortable: false,
          filterable: false,
          value: "tecnico",
        },
        {
          text: "Ingeniero",
          sortable: false,
          filterable: false,
          value: "ingeniero",
        },
        {
          text: "Placa",
          sortable: false,
          filterable: false,
          value: "placa_vehiculo",
        },
        {
          text: "Fecha de creación",
          sortable: false,
          filterable: false,
          value: "fecha_creacion",
        },
        {
          text: "Revisado",
          sortable: false,
          filterable: false,
          value: "revisado",
        },
        {
          text: "Aprobado",
          sortable: false,
          filterable: false,
          value: "aprobado",
        },
        {
          text: "Fecha de última revisión",
          sortable: false,
          filterable: false,
          value: "fecha_revision",
        },
        {
          text: "Comentarios del operario",
          sortable: false,
          filterable: false,
          value: "comentarios_operario",
        },
        {
          text: "Comentarios del ingeniero",
          sortable: false,
          filterable: false,
          value: "comentarios_ingeniero",
        },
        {
          text: "Acciones",
          sortable: false,
          filterable: false,
          value: "acciones",
        },
      ],

      dialogoConfirmacion: false, //Booleano que abre o cierra el diálogo de reestablecimiento

      formularioSeleccionado: {}, //Datos del formulario seleccionado

      loaderReset: false, //Spinner que se muestra al reestablecer el formulario

      activarDialogoFechas: false, //Booleano que activa el diálogo de fechas

      //Fecha de hoy
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      dateFinal: "",

      fechaInicial: false, //Fecha inicial

      fechaFinal: false, //Fecha final

      //Se trae el día de hoy como valor por defecto para la fecha inicial
      objetoFechas: {
        fecha_inicial: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        fecha_final: "",
      },

      loader_filtro_fechas: false, //Spinner que aparece al momento de procesar y filtrar las fechas
    };
  },

  async created() {
    this.$store.state.stateLoad = true;

    /* Método que trae todos los formularios diligenciados en el día de hoy. Se sigue el siguiente proceso */
    await apiLista
      .showAllForms({
        runt: this.runt,
        placa: this.placa,
        orden_trabajo: this.orden_trabajo,
        fecha_inicial: this.fecha_inicial,
        fecha_final: this.fecha_final,
      })
      .then((response) => {
        this.formularios = response.data;

        //Pasa por cada formulario
        this.formularios.forEach((formulario) => {
          /* Si la fecha de revisión y los comentarios de tanto el ingeniero como del operario están vacíos,
        se le asignan valores para mayor legibilidad a la hora de visualizar la tabla. */
          if (formulario.fecha_revision == null) {
            formulario.fecha_revision = "--";
          }
          if (formulario.comentarios_operario == null) {
            formulario.comentarios_operario = "N/A";
          }

          if (formulario.comentarios_ingeniero == null) {
            formulario.comentarios_ingeniero = "N/A";
          }
        });
      });

    this.$store.state.stateLoad = false;
  },

  methods: {
    async buscarFormularios() {
      this.buscando_formularios = true;
      await apiLista
        .showAllForms({
          runt: this.runt,
          placa: this.placa,
          orden_trabajo: this.orden_trabajo,
          fecha_inicial: this.fecha_inicial,
          fecha_final: this.fecha_final,
        })
        .then((response) => {
          this.formularios = response.data;

          //Pasa por cada formulario
          this.formularios.forEach((formulario) => {
            /* Si la fecha de revisión y los comentarios de tanto el ingeniero como del operario están vacíos,
        se le asignan valores para mayor legibilidad a la hora de visualizar la tabla. */
            if (formulario.fecha_revision == null) {
              formulario.fecha_revision = "--";
            }
            if (formulario.comentarios_operario == null) {
              formulario.comentarios_operario = "N/A";
            }

            if (formulario.comentarios_ingeniero == null) {
              formulario.comentarios_ingeniero = "N/A";
            }
          });
        });
      this.buscando_formularios = false;
    },
    /* Método que reestablece un formulario y elimina sus datos de revisión. */
    restoreItem() {
      this.loaderReset = true;

      apiLista
        .restoreForm(this.formularioSeleccionado.id_formulario) //Reestablece el formulario
        .then(() => {
          this.loaderReset = false;
          this.dialogoConfirmacion = false; //Cierra el diálogo y para el spinner
          apiLista.showAllForms().then((response) => {
            //Vuelve a refrescar los formularios
            this.formularios = response.data;

            /* Se hace el mismo proceso de asignar valores definidos a valores nulos */
            this.formularios.forEach((formulario) => {
              if (formulario.fecha_revision == null) {
                formulario.fecha_revision = "--";
              }
              if (formulario.comentarios_operario == null) {
                formulario.comentarios_operario = "N/A";
              }

              if (formulario.comentarios_ingeniero == null) {
                formulario.comentarios_ingeniero = "N/A";
              }
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /* Método que consigue los valores del formulario seleccionado */
    getItem(item) {
      this.formularioSeleccionado = item;
      this.dialogoConfirmacion = true;
    },

    /* Redirige hacia el menú de revisión */
    redirectToRevision(id) {
      this.$router.push({
        name: "formularioRevision",
        params: { id: id, revision: 1 },
      });
    },

    /* Método que permite corregir las fechas en un orden más coherente, es decir, la fecha final no puede
    ser menor que la inicial ni la fecha inicial puede ser menor que la final. */
    corregirFechas() {
      if (this.objetoFechas.fecha_final <= this.objetoFechas.fecha_inicial) {
        this.objetoFechas.fecha_final = this.objetoFechas.fecha_inicial;
      }
    },

    /* Método que filtra los formularios hechos en un período de tiempo, bajo unos parámetros de fecha
    inicial y final */
    filtrarPorFecha() {
      this.loader_filtro_fechas = true;
      apiLista.datesFilter(this.objetoFechas).then((response) => {
        //Filtra por fechas

        /* Recoge los resultados producto de dicho filtro */
        this.formularios = response.data;

        /* Se hace el mismo proceso de añadir valores definidos a valores nulos, con el propósito de añadir
        legibilidad a la tabla que se va a visualizar */
        this.formularios.forEach((formulario) => {
          if (formulario.fecha_revision == null) {
            formulario.fecha_revision = "--";
          }
          if (formulario.comentarios_operario == null) {
            formulario.comentarios_operario = "N/A";
          }

          if (formulario.comentarios_ingeniero == null) {
            formulario.comentarios_ingeniero = "N/A";
          }
        });

        this.loader_filtro_fechas = false;
        this.activarDialogoFechas = false;
      });
    },
  },
};
</script>

<style scoped>
>>> .v-data-table__wrapper {
  background-color: rgba(236, 236, 236, 0.8) !important;
  border-radius: 10px !important;
}

>>> td {
  border-bottom: 1px solid #adadad !important;
}

>>> .v-data-table__mobile-table-row {
  margin: 20px !important;
  border: 1px solid #adadad !important;
  border-radius: 10px !important;
}

>>> .v-data-table__mobile-row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
</style>
