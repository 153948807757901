var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"10"}},[_c('h2',{staticClass:"font-weight-bold mt-4 mb-10"},[_vm._v("Lista de formularios de hoy")]),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticClass:"mb-3 mt-n5",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.mostrar_filtros = !_vm.mostrar_filtros}}},[(!_vm.mostrar_filtros)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-filter-menu ")]):_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-filter-menu-outline ")]),_vm._v(" Filtros ")],1)],1),(_vm.mostrar_filtros)?_c('v-col',{staticClass:"mb-n8 mt-n6 mt-sm-0 mt-lg-0",attrs:{"cols":"12","sm":"3","md":"2","lg":"2"}},[_c('label',[_vm._v("N° Placa")]),_c('v-text-field',{attrs:{"rules":[
              function (v) { return /^[A-Za-z0-9]*$/.test(v) || 'Solo deben ser numeros y letras'; } ],"outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscarFormularios.apply(null, arguments)}},model:{value:(_vm.placa),callback:function ($$v) {_vm.placa=$$v},expression:"placa"}})],1):_vm._e(),(_vm.mostrar_filtros)?_c('v-col',{staticClass:"mb-n10",attrs:{"cols":"12","sm":"3","md":"2","lg":"2"}},[_c('label',[_vm._v("Orden Trabajo")]),_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscarFormularios.apply(null, arguments)}},model:{value:(_vm.orden_trabajo),callback:function ($$v) {_vm.orden_trabajo=$$v},expression:"orden_trabajo"}})],1):_vm._e(),(_vm.mostrar_filtros)?_c('v-col',{staticClass:"mb-n10",attrs:{"cols":"12","sm":"3","md":"2","lg":"2"}},[_c('label',[_vm._v("Runt")]),_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscarFormularios.apply(null, arguments)}},model:{value:(_vm.runt),callback:function ($$v) {_vm.runt=$$v},expression:"runt"}})],1):_vm._e(),(_vm.mostrar_filtros)?_c('v-col',{staticClass:"mb-n8",attrs:{"cols":"12","sm":"3","md":"3","lg":"2"}},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha inicial")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},on:{"click:append":function($event){_vm.menu_fecha_inicial = true}},model:{value:(_vm.fecha_inicial),callback:function ($$v) {_vm.fecha_inicial=$$v},expression:"fecha_inicial"}},'v-text-field',attrs,false),on))]}}],null,false,850457686),model:{value:(_vm.menu_fecha_inicial),callback:function ($$v) {_vm.menu_fecha_inicial=$$v},expression:"menu_fecha_inicial"}},[_c('v-date-picker',{attrs:{"locale":"es"},on:{"input":function($event){_vm.menu_fecha_inicial = false}},model:{value:(_vm.fecha_inicial),callback:function ($$v) {_vm.fecha_inicial=$$v},expression:"fecha_inicial"}})],1)],1):_vm._e(),(_vm.mostrar_filtros)?_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3","lg":"2"}},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha final")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","append-icon":"mdi-calendar","readonly":"","rules":[function (v) { return !!v || 'Campo requerido'; }]},on:{"click:append":function($event){_vm.menu_fecha_final = true}},model:{value:(_vm.fecha_final),callback:function ($$v) {_vm.fecha_final=$$v},expression:"fecha_final"}},'v-text-field',attrs,false),on))]}}],null,false,3228581455),model:{value:(_vm.menu_fecha_final),callback:function ($$v) {_vm.menu_fecha_final=$$v},expression:"menu_fecha_final"}},[_c('v-date-picker',{attrs:{"locale":"es","min":_vm.fecha_inicial},on:{"input":function($event){_vm.menu_fecha_final = false}},model:{value:(_vm.fecha_final),callback:function ($$v) {_vm.fecha_final=$$v},expression:"fecha_final"}})],1)],1):_vm._e(),(_vm.mostrar_filtros)?_c('v-col',{staticClass:"d-flex align-center justify-center mt-n8 mt-sm-0 mt-md-n5 mt-lg-0 mb-3 mb-sm-0 mb-lg-0",attrs:{"cols":"12","sm":"2","md":"2","lg":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.buscarFormularios}},[_vm._v(" Buscar "),_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-magnify ")])],1)],1):_vm._e()],1),(!_vm.buscando_formularios)?_c('v-data-table',{staticClass:"text-center",attrs:{"items-per-page":-1,"header-props":{ sortByText: 'Ordenar por' },"headers":_vm.headers,"items":_vm.formularios,"no-data-text":"No se encontraron resultados","elevation":"0"},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":item.fecha_revision == '--'},on:{"click":function($event){return _vm.getItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-undo-variant")]),_vm._v(" Reestablecer ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.redirectToRevision(item.id_formulario)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-text-box-search")]),_vm._v(" Visualizar ")],1)],1)],1)],1)]}},{key:"item.revisado",fn:function(ref){
            var item = ref.item;
return [(item.revisado == 1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):(item.revisado == 0)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.aprobado",fn:function(ref){
            var item = ref.item;
return [(item.aprobado == 1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):(item.aprobado == 2)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-minus")])]}},{key:"item.tarifa",fn:function(ref){
            var item = ref.item;
return [(item.tipo_revision != 0 && item.tipo_revision != 2)?_c('div',[_vm._v(" $"+_vm._s(_vm._f("moneda")(item.tarifa))+" ")]):_c('div',[_vm._v("No Aplica")])]}}],null,true)}):_c('v-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","height":"200px"},attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-progress-circular',{attrs:{"size":100,"color":"primary","indeterminate":""}}),_c('v-spacer')],1),_c('v-dialog',{attrs:{"persistent":"","width":"400px"},model:{value:(_vm.dialogoConfirmacion),callback:function ($$v) {_vm.dialogoConfirmacion=$$v},expression:"dialogoConfirmacion"}},[_c('v-card',{staticClass:"mx-auto text-center justify-center",attrs:{"width":"400px"}},[_c('v-card-title',{staticClass:"text-center justify-center mb-4 grey lighten-4"},[_vm._v("Reestablecer formulario")]),_c('v-card-text',{staticClass:"justify-center text-center"},[_c('div',{staticClass:"mb-2"},[_c('v-icon',{attrs:{"size":"60"}},[_vm._v("mdi-help-circle-outline")])],1),_c('span',[_vm._v("¿Está seguro de que desea reestablecer este formulario?")])]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"success","small":"","loading":_vm.loaderReset},on:{"click":function($event){return _vm.restoreItem()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-check")]),_vm._v(" Sí ")],1),_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){_vm.dialogoConfirmacion = false}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" No ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }